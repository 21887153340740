import axios from 'axios';
import qs from 'qs';
import VueRouter from 'vue-router'
import routes from '../router/routes'
// import env from './env'

//获取token
var user = localStorage.getItem('user'),
    dataToken,
    apiVersion = localStorage.getItem('apiVersion');
if (user) {
    user = JSON.parse(user);
    dataToken = user.token;
    localStorage.setItem( 'access_token',dataToken )
}

const router = new VueRouter({
    routes
})

var instance = axios.create({
    timeout: 30000,
    headers: {
        'token': dataToken,
        'api-version': apiVersion
    }
});

// 添加响应拦截器 
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let code;
    if(response.data!= null && response.data.code){
        code = response.data.code
    }
    if(code == '-99999' || code == '-99998'){
        router.replace({ name: 'login' })
    }else if(code == '-99997'){
        router.replace({ path: '/main' })
    }else if(code == '-99996'){
        //创建提示框
        var noteNoper = document.getElementById("noteNoper");
        if(!noteNoper){
            var div = document.createElement("div");
            div.id = 'noteNoper';
            var divattr = document.createAttribute("class");
            divattr.value = "el-message";
            div.setAttributeNode(divattr);
            var text = document.createTextNode("您无权访问");
            div.appendChild(text);
            document.getElementsByTagName("body").item(0).appendChild(div);
            setTimeout(_=>{
                var n = document.getElementById("noteNoper");
                n.parentNode.removeChild(n);
            },3000)
        }
        //无权限返回首页
        // router.replace({ name: '首页' })
        router.go(-1)
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
}); 
let apiUrl = process.env.VUE_APP_ROOT
let apiUrlBase = process.env.VUE_APP_BASE
let apiUrlPay = process.env.API_ROOT_PAY
let apiOdds = process.env.VUE_APP_ODDS
let qiniu = process.env.VUE_APP_Qiniu
let apiLiezhen = process.env.VUE_APP_LIEZHEN

//登录验证 
export const requestLogin = params => { return instance.post(`${apiUrl}/auth/login/login`, qs.stringify(params)).then(res => res.data); }; //用户名登陆
export const logout = params => { return instance.post(`${apiUrl}/auth/user/logout`, qs.stringify(params)).then(res => res.data); }; //登出
export const updatePassword = params => { return instance.post(`${apiUrl}/auth/user/updatePassword`, qs.stringify(params)).then(res => res.data); };  // 修改密码
export const getMenu = params => { return instance.get(`${apiUrl}/auth/auth/getMenu`, { params: params }).then(res => res.data); };//获取目录菜单权限
export const send = params => { return instance.post(`${apiUrlBase}/api/sms/send`, qs.stringify(params)).then(res => res.data); };  // 修改密码


//-------------------------------------------------------公用部分-------------------------------------------------------
//文件上传地址
export const uploadFiles = params => { return instance.post(qiniu, qs.stringify(params)); };
//获取上传文件token
export const getUploadToken = params => { return instance.get(`${apiUrl}/api/qiniu/gettoken`, { params: params }).then(res => res.data); };
//提交视频地址转码
export const transcoding = params => { return instance.post(`${apiUrl}/api/qiniu/transcoding`, qs.stringify(params)).then(res => res.data); };
//获取视频播放地址
export const getVideoUrl = params => { return instance.post(`${apiUrl}/api/qiniu/getVideoUrl`, qs.stringify(params)).then(res => res.data); };
//获取省份
export const showProvince = params => { return instance.get(`${apiUrl}/api/area/showprovince`, { params: params }).then(res => res.data); };
//获取市县
export const showCity = params => { return instance.get(`${apiUrl}/api/area/showcity`, { params: params }).then(res => res.data); };


//-----------------------------角色----账号-----权限----------------------------------
//获取管理员列表
export const getAdminList = params => { return instance.get(`${apiUrl}/auth/user/index`, { params: params }).then(res => res.data);};
//获取部门列表
export const getGroupList = params => { return instance.get(`${apiUrl}/auth/group/index`, { params: params }).then(res => res.data); };
//更改管理员状态
export const stateAdmin = params => { return instance.post(`${apiUrl}/auth/user/delete`, qs.stringify(params)).then(res => res.data); };
//编辑管理员
export const saveAdmin = params => { return instance.post(`${apiUrl}/auth/user/edit`, qs.stringify(params)).then(res => res.data); };
//新增管理员
export const addAdmin = params => { return instance.post(`${apiUrl}/auth/user/add`, qs.stringify(params)).then(res => res.data); };
//查看管理员
export const viewAdmin = params => { return instance.get(`${apiUrl}/auth/user/view`, { params: params }).then(res => res.data); };

//获取角色组列表分页
export const getAdminRoleList = params => { return instance.get(`${apiUrl}/auth/role/index`, { params: params }).then(res => res.data); };
//更改管理员状态
export const stateAdminRule = params => { return instance.post(`${apiUrl}/auth/role/delete`, qs.stringify(params)).then(res => res.data); };
//获取角色组信息
export const viewAdminRole = params => { return instance.get(`${apiUrl}/auth/role/view`, { params: params }).then(res => res.data); };
//编辑角色组
export const saveAdminRole = params => { return instance.post(`${apiUrl}/auth/role/edit`, qs.stringify(params)).then(res => res.data); };
//新增角色组
export const addAdminRole = params => { return instance.post(`${apiUrl}/auth/role/add`, qs.stringify(params)).then(res => res.data); };
//获取权限节点
export const getPsmNode = params => { return instance.get(`${apiUrl}/auth/role/menuList`, { params: params }).then(res => res.data); };

//-----------------数据------
// 竞彩足球
export const jcList = params => { return instance.get(`${apiUrl}/spider/index/jcList`, { params: params }).then(res => res.data); };
// 北京单场
export const bdList = params => { return instance.get(`${apiUrl}/spider/index/bdList`, { params: params }).then(res => res.data); };
// 14场和任九
export const zcList = params => { return instance.get(`${apiUrl}/spider/index/zcList`, { params: params }).then(res => res.data); };
// 数字彩期数获取
export const qishuNumber = params => { return instance.get(`${apiUrl}/spider/Number/getQishu`, { params: params }).then(res => res.data); };
// 数字某一期重新开奖
export const reopenNumber = params => { return instance.get(`${apiUrl}/spider/Number/reopen`, { params: params }).then(res => res.data); };
// 期数 
export const qishu = params => { return instance.get(`${apiUrl}/spider/index/getQishu`, { params: params }).then(res => res.data); };
//修改数据
export const modify = params => { return instance.post(`${apiUrl}/spider/index/modify`, qs.stringify(params)).then(res => res.data); };
//绑定合作店铺
export const coopBind = params => { return instance.post(`${apiUrl}/store/coop/bind`, qs.stringify(params)).then(res => res.data); };


//-----------------店铺------
// 账号创建
export const addstore = params => { return instance.post(`${apiUrl}/store/index/addstore`, qs.stringify(params)).then(res => res.data); };
//获取账号列表
export const getStoreList = params => { return instance.get(`${apiUrl}/store/index/createUserHistory`, { params: params }).then(res => res.data); };
//获取店铺详情
export const getStoreInfo = params => { return instance.get(`${apiUrl}/store/index/storeInfo`, { params: params }).then(res => res.data); };
//修改店铺信息
export const updateStoreInfo = params => { return instance.post(`${apiUrl}/store/index/updateStoreInfo`, qs.stringify(params)).then(res => res.data); };
// 域名状态
export const getThoststatus = params => { return instance.get(`${apiUrl}/store/index/gethoststatus`, { params: params }).then(res => res.data); };
// 域名注册
export const hostRegister = params => { return instance.post(`${apiUrl}/store/domain/hostRegister`, qs.stringify(params)).then(res => res.data); };
// 域名发送
export const hostSend = params => { return instance.post(`${apiUrl}/store/domain/hostSend`, qs.stringify(params)).then(res => res.data); };
// 域名确认
export const hostConfirm = params => { return instance.post(`${apiUrl}/store/domain/hostConfirm`, qs.stringify(params)).then(res => res.data); };
//获取所有店铺数据  
export const storeData = params => { return instance.get(`${apiUrl}/store/index/selectStoreInfo`, { params: params }).then(res => res.data); };
//确定开通
export const openSuccess = params => { return instance.post(`${apiUrl}/store/domain/openSuccess`, qs.stringify(params)).then(res => res.data); };
//升级高级版
export const modulesUpdate = params => { return instance.post(`${apiUrl}/store/modules/modulesUpdate`, qs.stringify(params)).then(res => res.data); };
//获取高级版模块
export const advanceModulesShow = params => { return instance.get(`${apiUrl}/store/modules/advanceModulesShow`, { params: params }).then(res => res.data); };
//服务点管理
export const addServicePoint = params => { return instance.get(`${apiUrl}/store/index/addServicePoint`, { params: params }).then(res => res.data); };

//-----------------进件管理------
// 进件
export const into = params => { return instance.post(`${apiUrlPay}/trade/merchant/into`, qs.stringify(params)).then(res => res.data); };
// 进件列表
export const merchant_list = params => { return instance.get(`${apiUrlPay}/operation/merchant_list/index`, { params: params }).then(res => res.data); };

// 用户留言
export const showMsg = params => { return instance.get(`${apiUrl}/order/index/showMsg`, { params: params }).then(res => res.data); };
// 用户留言
export const markMsg = params => { return instance.post(`${apiUrl}/order/index/markMsg`, params).then(res => res.data); };
//-----------------订单管理------
export const orderList = params => { return instance.get(`${apiUrl}/order/index/orderList`, { params: params }).then(res => res.data); };
// 修改订单
export const confirmByHand = params => { return instance.post(`${apiUrl}/order/index/confirmByHand`, qs.stringify(params)).then(res => res.data); };
// 创建订单
export const createOrderByHand = params => { return instance.post(`${apiUrl}/order/index/createOrderByHand`, qs.stringify(params)).then(res => res.data); };


//-----------------日常运营------
// 短信列表
export const smsList = params => { return instance.get(`${apiUrl}/other/sms/index`, { params: params }).then(res => res.data); };
// banner列表
export const bannerList = params => { return instance.get(`${apiUrl}/other/banner/index`, { params: params }).then(res => res.data); };
// 添加banner
export const addImg = params => { return instance.post(`${apiUrl}/other/banner/add`, qs.stringify(params)).then(res => res.data); };
// 删除banner
export const deleteImg = params => { return instance.get(`${apiUrl}/other/banner/delete`, { params: params }).then(res => res.data); };
// 修改banner
export const statusImg = params => { return instance.get(`${apiUrl}/other/banner/status`, { params: params }).then(res => res.data); };
// 商户查询列表
export const searchrList = params => { return instance.get(`${apiUrl}/store/rate/storeBindMerchant`, { params: params }).then(res => res.data); };
// 绑定商户详情
export const bindMerchantList = params => { return instance.get(`${apiUrl}/store/rate/bindMerchantList`, { params: params }).then(res => res.data); };
// 商户绑定操作
export const bindMerchant = params => { return instance.post(`${apiUrl}/store/rate/bindMerchant`, { params: params }).then(res => res.data); };
// 费率切换商户
export const rateChangeList = params => { return instance.get(`${apiUrl}/store/rate/rateChange`, { params: params }).then(res => res.data); };
// 方案列表
export const planlist = params => { return instance.get(`${apiUrl}/plan/index/orderlist`, { params: params }).then(res => res.data); };
// 设置传票
export const setChoucha = params => { return instance.post(`${apiUrl}/plan/index/setChoucha`, qs.stringify(params)).then(res => res.data); };
// 方案详情
export const planDetail = params => { return instance.get(`${apiUrl}/plan/index/detail`, { params: params }).then(res => res.data); };
// 重新开奖
export const reopen = params => { return instance.get(`${apiUrl}/spider/index/reopen`, { params: params }).then(res => res.data); };
// 人工检查票样无误后通过检查
export const setPass = params => { return instance.get(`${apiUrl}/plan/index/setPass`, { params: params }).then(res => res.data); };

// 商户列表
export const merchantList = params => { return instance.get(`${apiUrl}/store/rate/merchantList`, { params: params }).then(res => res.data); };
// 商户管理
export const getStroeList = params => { return instance.get(`${apiUrl}/store/Merchant/getStroeList`, { params: params }).then(res => res.data); };
// 获取默认通道列表
export const getChannelList = params => { return instance.get(`${apiUrl}/store/Merchant/getChannelList`, { params: params }).then(res => res.data); };
// 获取店铺的进件信息列表
export const getStoreTradingInfo = params => { return instance.get(`${apiUrl}/store/Merchant/getStoreTradingInfo`, { params: params }).then(res => res.data); };
// 修改支付信息
export const updateTradingInfo = params => { return instance.post(`${apiUrl}/store/Merchant/updateTradingInfo`, params).then(res => res.data); };

// 获取店铺APP列表
export const storeAppList = params => { return instance.get(`${apiUrl}/other/store_app/index`, { params: params }).then(res => res.data); };
// 店铺app上传
export const storeAppAdd = params => { return instance.post(`${apiUrl}/other/store_app/addOne`, params ).then(res => res.data); };
// 店铺app更新
export const storeAppUpdate = params => { return instance.post(`${apiUrl}/other/store_app/update`, params ).then(res => res.data); };

// 获取店铺cAPP列表
export const userAppList = params => { return instance.get(`${apiUrl}/other/user_app/index`, { params: params }).then(res => res.data); };
// 店铺capp上传
export const userAppAdd = params => { return instance.post(`${apiUrl}/other/user_app/addOne`, params ).then(res => res.data); };
// 店铺capp更新
export const userAppUpdate = params => { return instance.post(`${apiUrl}/other/user_app/update`, params ).then(res => res.data); };

// 打印机订单列表
export const getOrderList = params => { return instance.get(`${apiUrl}/order/Printorder/getOrderList`, { params: params } ).then(res => res.data); };
// 打印机订单列详情
export const getOrderInfo = params => { return instance.get(`${apiUrl}/order/Printorder/getOrderInfo`, { params: params } ).then(res => res.data); };
// 修改订单
export const updateOrder = params => { return instance.post(`${apiUrl}/order/Printorder/updateOrder`, params ).then(res => res.data); };


//通知
export const noticeList = params=> { return instance.get(`${apiUrl}/other/notice/noticeList`, { params: params }).then(res => res.data); };
export const noticeDel = params=> { return instance.get(`${apiUrl}/other/notice/noticeDel`, { params: params }).then(res => res.data); };
export const noticePush = params=> { return instance.post(`${apiUrl}/other/notice/noticePush`,params).then(res => res.data); };

// 进件
export const dataPost = params=> { return instance.post(`${apiUrl}/store/jinjian/dataPost`,params).then(res => res.data); };
export const audit = params=> { return instance.post(`${apiUrl}/store/jinjian/audit`,params).then(res => res.data); };
export const statistics = params=> { return instance.get(`${apiUrl}/store/Merchant/statistics`,{ params: params }).then(res => res.data); };

// 投诉列表
export const tousuList = params=> { return instance.get(`${apiUrl}/other/Complaint/getList`,{ params: params }).then(res => res.data); };
// 处理投诉
export const doComplaint = params=> { return instance.post(`${apiUrl}/other/Complaint/doComplaint`, params).then(res => res.data); };
// 申诉列表
export const getShensuList = params=> { return instance.get(`${apiUrl}/other/Complaint/getShensuList`,{ params: params }).then(res => res.data); };


// 传票统计
export const getPlanStatisticsList = params=> { return instance.get(`${apiUrl}/plan/index/getPlanStatisticsList`, { params: params }).then(res => res.data); };
// 传票列表
export const getPlanList = params=> { return instance.get(`${apiUrl}/plan/index/getPlanList`, { params: params }).then(res => res.data); };

// 赛事管理
export const getAssocMatch = params=> { return instance.get(`${apiLiezhen}/api/live/getAssocMatch`, { params: params }).then(res => res.data); };
export const delLive = params=> { return instance.post(`${apiLiezhen}/api/live/del`, params).then(res => res.data); }; //删除赛事
export const savAssocMatch = params=> { return instance.post(`${apiLiezhen}/api/live/savAssocMatch`, params).then(res => res.data); }; // 开启关闭北单
export const searchLeague = params=> { return instance.get(`${apiLiezhen}/api/live/searchLeague`, { params: params }).then(res => res.data); };
export const searchGame = params=> { return instance.get(`${apiOdds}/api/game/search`, { params: params }).then(res => res.data); };


//获取谷歌二维码
export const getgoogleqr = params => { return instance.get(`${apiUrl}/auth/user/getgoogleqr`, { params: params }).then(res => res.data);};

//校验谷歌绑定
export const getgooglekey = params => { return instance.get(`${apiUrl}/auth/user/getgooglekey`, { params: params }).then(res => res.data);};

// 获取标识列表
export const identifier = params => { return instance.get(`${apiUrl}/identifier/index/index`, { params: params }).then(res => res.data); };
// 添加标识
export const addidentifier = params => { return instance.get(`${apiUrl}/identifier/index/addidentifier`, { params: params }).then(res => res.data); };
// 编辑标识
export const upidentifier = params => { return instance.get(`${apiUrl}/identifier/index/update`, { params: params }).then(res => res.data); };
// 删除标识
export const deleteidentifier = params => { return instance.get(`${apiUrl}/identifier/index/delete`, { params: params }).then(res => res.data); };
// 返回标识列表
export const identifierlist = params => { return instance.get(`${apiUrl}/identifier/index/identifierlist`, { params: params }).then(res => res.data); };

// 获取月报表
export const getmoon = params => { return instance.get(`${apiUrl}/finance/index/moon`, { params: params }).then(res => res.data); };
// 获取日报表
export const getsun = params => { return instance.get(`${apiUrl}/finance/index/sun`, { params: params }).then(res => res.data); };