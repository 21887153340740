<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item>
						<el-input v-model="filters.keyword" placeholder="输入内容"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
				</span>
				<el-form-item>
					<el-button type="primary" @click="handleAdd" icon="el-icon-plus">创建</el-button>
				</el-form-item>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;">
			<el-table-column type="selection">
			</el-table-column>
			<el-table-column prop="id" label="ID" width="60">
			</el-table-column>
			<el-table-column prop="name" label="角色组" sortable>
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间">
			</el-table-column>
			<el-table-column prop="remark" label="备注" sortable>
			</el-table-column>
			<el-table-column label="状态" width="80" align="center">
				<template slot-scope="scope">
					<span class="table-cell-status">
							<i class="yellow el-icon-warning" v-if="scope.row.status=='2'" title="冻结"></i>
							<i class="green el-icon-success" v-else title="正常"></i>
						</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200">
				<template slot-scope="scope">
					<el-button type="success" plain size="small" @click="handleState(scope.$index, scope.row, '恢复')" v-if="scope.row.status=='2'" icon="el-icon-refresh" title="恢复"></el-button>
					<el-button type="warning" plain size="small" @click="handleState(scope.$index, scope.row, '冻结')" icon="el-icon-warning" title="冻结" v-else></el-button>
					<el-button type="primary" plain size="small" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
					<el-button type="danger" plain size="small" @click="handleState(scope.$index, scope.row, '删除')" icon="el-icon-delete" title="删除"></el-button>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-button type="danger" @click="handleState('', '', '批量删除')" :disabled="this.sels.length===0" icon="el-icon-delete">批量删除</el-button>
			<el-button type="warning" @click="handleState('', '', '批量冻结')" :disabled="this.sels.length===0" icon="el-icon-warning">批量冻结</el-button>
			<el-button type="success" @click="handleState('', '', '批量恢复')" :disabled="this.sels.length===0" icon="el-icon-refresh">批量恢复</el-button>
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>

	</section>
</template>

<script>

	import { getAdminRoleList, stateAdminRule} from '../../api/api';
	export default {
		data() {
			return {
				filters: {
					keyword: ''
				},
				listData: [],

				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				sels: []

			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取列表
			getList(val) {
                if(val == '0') this.page = 1
				let para = {
					page: this.page,
					name: this.filters.keyword,
					pagesize: this.pagesize	//分页条数
				};
				this.listLoading = true;
				getAdminRoleList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//显示编辑界面
			handleEdit: function (index, row) {
				this.$router.push({ name: '编辑系统角色', query: { id: row.id }})
			},
			//跳转新增界面
			handleAdd: function () {
				this.$router.push({ name: '编辑系统角色'})
			},
			//勾选
			selsChange: function (sels) {
				this.sels = sels;
			},
			//恢复、冻结、删除
			handleState: function (index, row, text) {
				this.$confirm('确认'+text+'该用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					this.listLoading = true;
					var astatus = function(){
						return text == '冻结' ? 2 : text == '恢复' ? 1 : text == '批量冻结' ? 2 : text == '批量恢复' ? 1 : '0';
					}
					//判断是否批量
					if(row){
						var para = {
							id: row.id,
							status: astatus()
						};
					}else {
                        var ids = this.sels.map(item => item.id).toString();
						var para = {
							id: ids,
							status: astatus()
						};
					}
					stateAdminRule(para).then((res) => {
						this.listLoading = false;
						if(res.code==1){
							this.$message({
								message: text+'成功',
								type: 'success'
							});
							this.getList();
						}else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
						}
					});
				}).catch(() => {

				});
			},
		},
		mounted() {
			//获取列表
			this.getList();
		}
	}

</script>